import defaultParams, { DefaultParams } from './defaultParams'
import DeviceApi from './api/devices.api'
import VehicleApi from './api/vehicles.api'
import MediaApi from './api/media.api'
import GPSApi from './api/gps.api'
import { EventEmitter } from 'events'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import actions, { IActions } from './actions'
import FleetApi from './api/fleet.api'
// import GPSApi from "./api/gps.api";

export default class StreamaxJSSdk {
    public static config: DefaultParams;
    public static actions:IActions = actions
    public static event: EventEmitter = new EventEmitter()

    public api!: { Device: DeviceApi; Vehicle: VehicleApi; Media: MediaApi, Gps: GPSApi, Fleet: FleetApi };

    constructor (_sign: DefaultParams['_sign'], _tenantid: DefaultParams['_tenantid'], config?: DefaultParams) {
      StreamaxJSSdk.config = defaultParams({ ...config, _sign, _tenantid })
      this.instanceApiClasses()
      this.loopFetchData()
    }

    private instanceApiClasses () {
      this.api = {
        Device: new DeviceApi(this),
        Vehicle: new VehicleApi(this),
        Media: new MediaApi(this),
        Gps: new GPSApi(this),
        Fleet: new FleetApi(this)
      }
    }

    private loopFetchData () {
      setInterval(async () => {
        if (!StreamaxJSSdk.config.disableInterval) {
          try {
            await StreamaxJSSdk.actions.AUnit.observeMessages()
            await StreamaxJSSdk.actions.AAlert.observeMessages()
          } catch (error) {
            console.error(error)
          }
        }
      }, StreamaxJSSdk.config.intervalTime)
    }

    public static async axios<D = any> (reqConfig: AxiosRequestConfig = {}) {
      const baseURL = (StreamaxJSSdk.config.proxyUrl === null)
      ? `${StreamaxJSSdk.config.baseURL}/openapi/${StreamaxJSSdk.config.apiVersion}`
      : `${StreamaxJSSdk.config.baseURL}/${StreamaxJSSdk.config.proxyUrl}/${StreamaxJSSdk.config.apiVersion}`
      const instance = axios.create({
        ...reqConfig,
        baseURL,
        headers: {
          _sign: StreamaxJSSdk.config._sign,
          _tenantid: StreamaxJSSdk.config._tenantid,
          ...reqConfig.headers
        }
      })

      return await instance(reqConfig) as AxiosResponse<D>
    }

    public static async axiosV3<D = any> (reqConfig: AxiosRequestConfig = {}) {
      const baseURL = (StreamaxJSSdk.config.proxyUrl === null)
      ? `${StreamaxJSSdk.config.baseURL}/openapi/`
      : `${StreamaxJSSdk.config.baseURL}/${StreamaxJSSdk.config.proxyUrl}`

      const instance = axios.create({
        ...reqConfig,
        baseURL,
        headers: {
          _sign: StreamaxJSSdk.config._sign,
          _tenantid: StreamaxJSSdk.config._tenantid,
          ...reqConfig.headers
        }
      })
      return await instance(reqConfig) as AxiosResponse<D>
    }
}

import { ActionContext } from 'vuex'
import { State } from './state'
import Ticket, { ITicket } from '@/classes/Ticket/ITicket'
import objToFormData from '@/utils/formdatacomment'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    dispatch('loadTicket')
  },
  async loadTicket ({ commit, dispatch }: ActionContext<State, any>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/ticket/get/all',
          method: 'GET'
        },
        { root: true }
      )
      const tickets = data.map((ticket: ITicket) => new Ticket(ticket))
      commit('SET_TICKETS', tickets)
      return tickets
    } catch (error) {
      dispatch('app/addLogWithError', {
        title: 'Error al cargar los códigos QR',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, ticket: ITicket) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/ticket/create',
          method: 'POST',
          data: ticket
        },
        { root: true }
      )
      commit('PUSH_TICKET', data)
      await dispatch('app/notification', { content: 'Ticket Creado', type: 'success' }, { root: true })
      return data
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido crear el Ticket de activos', type: 'error' }, { root: true })
      throw error
    }
  },
  async createComment ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    console.log(formData)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/comment/${payload.ticketId}`,
        method: 'POST',
        data: formData
      },
      { root: true }
    )
    commit('UPDATE_TICKET', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, assignmentData: { ticketId: number, action: string, assignment_id: number }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/ticket/update/${assignmentData.ticketId}`,
          method: 'PUT',
          data: assignmentData
        },
        { root: true }
      )
      commit('UPDATE_TICKET', data)
      return data
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido dar seguimiento al Ticket', type: 'error' }, { root: true })
      throw error
    }
  },
  async reasigned ({ commit, dispatch }: ActionContext<State, any>, reassignData: { ticketId: number, payload: any }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/ticket/reasigned/${reassignData.ticketId}`,
          method: 'POST',
          data: reassignData.payload
        },
        { root: true }
      )
      commit('UPDATE_TICKET', data)
      await dispatch('app/notification', { content: 'Ticket reasignado', type: 'success' }, { root: true })
      return data
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido reasignar el ticket', type: 'error' }, { root: true })
      throw error
    }
  },
  async loadTicketById ({ commit, dispatch }: ActionContext<State, any>, id: number) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/ticket/get/${id}`,
          method: 'GET'
        },
        { root: true }
      )
      commit('UPDATE_TICKET', data)
      return data
    } catch (error) {
      dispatch('app/addLogWithError', {
        title: 'Error al cargar los códigos QR',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async updateTicket ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/updateTicket/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_TICKET', data)
    return data
  },
  async updateComment ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/edit-comment/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    return data
  }
}

import { ActionContext } from 'vuex'
import { State } from './state'

const URL_ACTION = '/apis/crm_sales/'
export default {
  async load ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'get/all',
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'update/' + payload.id,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'delete/' + payload.id,
        method: 'DELETE'
      },
      { root: true }
    )
    commit('DELETE', payload)
    return data
  },
  async create_note ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'note/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadByCompany ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: 'apis/crm_companies/get/' + payload,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadDashboard ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'dashboard',
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadConfig ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'get/config',
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async createConfig ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'create/config',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async updateConfig ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'update/config/' + payload.id,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadByIdSale ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'get/' + payload,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async importExcel ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'import/excel',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async createSalesImport ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION + 'import/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  }
}


import { ActionContext } from 'vuex'
import { State } from './state'
import { DateTime } from 'luxon'
// import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/invoice/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, payload = { search: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE', data)
    return data
  },
  async timbrarXml ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const response = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}timbrar_xml`,
        method: 'POST',
        data: payload,
        responseType: 'blob' // Asegúrate de recibir los datos como un blob
      },
      { root: true }
    )

    const blob = new Blob([response.data], { type: 'application/xml' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${payload.folio}.xml`) // Nombre del archivo
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    return response.data // Si necesitas devolver algo a la función que llama
  },
  async getPDF ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const now = DateTime.local()
    const timezoneOffsetHours = now.offset / 60
    const response = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}pdf/${payload.id}?timezone=${timezoneOffsetHours}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      },
      { root: true }
    )

    const blob = new Blob([response.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${payload.folio}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)

    return response.data
  }
}
export default actions

import { USER_TYPES } from './USER_TYPES'
import { State, IService } from './state'
export default {
  getUsers (state: State) {
    return state.users
  },
  getUsersMirrors (state: State) {
    // @ts-ignore
    return state.users.filter(user => user.userType === USER_TYPES.USER_MIRROR)
  },
  getUserDrivers (state: State) {
    // @ts-ignore
    if (!state.users) return []
    // @ts-ignore
    return state.users?.filter(user => user.userType === USER_TYPES.CONDUCTOR && user.is_active).map(userDriver => {
      return {
        // @ts-ignore
        id: userDriver.userID,
        // @ts-ignore
        username: userDriver.userName,
        // @ts-ignore
        userType: userDriver.userType,
        // @ts-ignore
        phone: userDriver.phone,
        // @ts-ignore
        id_user_profile: userDriver.id_user_profile
      }
    })
  },
  getServices (state: State) {
    // @ts-ignore
    const services: IService[] = state.services.map((service) => {
      let { id, name, permissions, group_order, group } = service
      if (name === 'MANTENIMIENTO') {
        name = 'Control de mantenimientos'
      } else if (name === 'VIAJE') {
        name = 'Control de viajes'
      } else if (name === 'LLANTA') {
        name = 'Control de llantas'
      } else if (name === 'BATERIA') {
        name = 'Control de baterias'
      } else if (name === 'COMBUSTIBLE') {
        name = 'Control de combustible'
      } else if (name === 'MAPAS') {
        name = 'Mapas'
      } else if (name === 'CAMARAS') {
        name = 'Cámaras'
      } else if (name === 'CHECKLIST') {
        name = 'Checklist'
      } else if (name === 'INVENTARIO') {
        name = 'Inventario'
      } else if (name === 'FACTURACION') {
        name = 'Facturación'
      } else if (name === 'TICKETS') {
        name = 'Tickets'
      } else if (name === 'PANEL') {
        name = 'Panel'
      } else if (name === 'CRM') {
        name = 'CRM'
      }
      return { id, name, permissions, group_order, group }
    })
    services.sort((a, b) => {
      return a.group_order - b.group_order
    })
    console.log(services)
    return services
  },
  getUserType: () => (id_user_type: number) => {
    if (id_user_type === USER_TYPES.MONITOREO) return { label: 'Empresa de rastreo', icon: '', color: 'info' }
    if (id_user_type === USER_TYPES.ADMINISTRADOR) return { label: 'Administrador', icon: '', color: 'info' }
    if (id_user_type === USER_TYPES.CLIENTE) return { label: 'Cliente', icon: '', color: 'info' }
    if (id_user_type === USER_TYPES.SUPERVISOR) return { label: 'Supervisor', icon: '', color: '' }
    if (id_user_type === USER_TYPES.USER_MIRROR) return { label: 'Espejo', icon: 'mdi-account', color: 'black' }
    if (id_user_type === USER_TYPES.CONDUCTOR) return { label: 'Conductor', icon: 'mdi-card-account-details', color: 'blue' }
    return { label: 'Sin datos', icon: '', color: '' }
  },
  getTypeChild: () => (id_user_type: number) => {
    if (id_user_type === USER_TYPES.MONITOREO) return 'Cliente'
    // if (id_user_type === USER_TYPES.ADMINISTRADOR) return 'Administrador'
    // if (id_user_type === USER_TYPES.CLIENTE) return 'Supervisores'
    if (id_user_type === USER_TYPES.USER_MIRROR) return 'Espejo'
    // if (id_user_type === USER_TYPES.SUPERVISOR) return 'Supervisor'
    return 'Sin datos'
  },
  getUserTypeTree: () => (id_user_type: number) => {
    if (id_user_type === USER_TYPES.MONITOREO) return 'Empresas'
    // if (id_user_type === USER_TYPES.ADMINISTRADOR) return 'Administrador'
    if (id_user_type === USER_TYPES.CLIENTE) return 'Clientes'
    // if (id_user_type === USER_TYPES.SUPERVISOR) return 'Supervisores'
    if (id_user_type === USER_TYPES.USER_MIRROR) return 'Espejos'
    if (id_user_type === USER_TYPES.CONDUCTOR) return 'Conductores'
    return 'Sin datos'
  },
  isClient (state: State, getters: { roleId: USER_TYPES }) {
    return getters.roleId === USER_TYPES.CLIENTE
  },
  isMirror (state: State, getters: { roleId: USER_TYPES }) {
    return getters.roleId === USER_TYPES.USER_MIRROR
  },
  isMonitorist (state: State, getters: { roleId: USER_TYPES }) {
    return getters.roleId === USER_TYPES.MONITOREO
  }
}

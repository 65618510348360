// import { State } from '../../state'

import { ActionContext } from 'vuex'
import { IRequisition } from '../../../../classes/Inventory/interfaces/IRequisition'
import { State } from './state'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/requisition/',
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_REQUISITION', data)
    return data
  },
  async getById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/requisition/${id}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async createRequisition ({ commit, dispatch }: ActionContext<State, any>, payload: IRequisition) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/requisition/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH_REQUISITION', data)
    return data
  },
  async authorizedRequisition ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/requisition/authorized',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_REQUISITION', data)
    return data
  },
  async denniedRequisition ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/requisition/dennied',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_REQUISITION', data)
    return data
  },
  async updateRequisition ({ commit, dispatch }: ActionContext<State, any>, payload: IRequisition) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/requisition/update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_REQUISITION', data)
    return data
  },
  async loadRequisitionMovementLastest ({ commit, dispatch }: ActionContext<State, any>, id_requisition:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/requisition/movements/lastest/${id_requisition}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}
export default actions

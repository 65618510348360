import { Module } from 'vuex'
import { sales } from './modules/sales'
import { companies } from './modules/companies'

export const CRM: Module<any, any> = {
  namespaced: true,
  modules: {
    sales,
    companies
  }
}
